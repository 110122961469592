import { datadogLogs } from '@datadog/browser-logs'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { EntryType } from 'model/common'
import {
  Matrix,
  ResponseChoice,
  ResponseOptionChoice
} from 'model/responseChoice'
import { PfmContext } from 'containers/PageFlowManager/PageFlowManager'
import { useContext } from 'react'
import { RESPONSE_CHOICE_API_PATH } from 'utils/constants'
import { getNowTimeString, getUtcOffset } from 'utils/datetime'
import { apiUrlEndpoint } from 'utils/helper'
import { useQuestionnaireParams } from './useQuestionnaireParams'
import { usePostCallbackWithRetry } from './usePostCallback'

interface Variables {
  questionId: string
  entry: EntryPositionProps
  entryType: EntryType
  questionTypeCode: string
  renderingDateTime: string
  responseChoices: ResponseOptionChoice[]
  matrix?: Matrix
  experimentalDesignVersion?: number
}

interface EntryPositionProps {
  entryPosition: number
  renderedEntryPosition: number
  contextPosition: number
  renderedContextPosition: number
}

const endpoint = `${apiUrlEndpoint}${RESPONSE_CHOICE_API_PATH}`

const useSendResponseChoice = () => {
  const mandatoryQueryParams = useQuestionnaireParams()
  const {
    respondentProgress: [progress, setProgress]
  } = useContext<AppState>(AppStateContext)
  const sendResponseChoice = usePostCallbackWithRetry<ResponseChoice>()
  const { next } = useContext(PfmContext)

  const sendResponseOptionChoices = ({
    questionId,
    entry,
    entryType,
    questionTypeCode,
    renderingDateTime,
    responseChoices,
    matrix,
    experimentalDesignVersion
  }: Variables) => {
    if (!mandatoryQueryParams) {
      // NOTE: should never get here
      datadogLogs.logger.error(
        'Response choice failed to be sent. Mandatory params missing. Ensure `sid`, `rid` and `ps` are specified',
        { questionId, matrix, responseChoices }
      )
      return
    }

    const payload: ResponseChoice = {
      surveyId: mandatoryQueryParams.surveyId,
      panelSupplierCode: mandatoryQueryParams.panelSupplier,
      respondentId: mandatoryQueryParams.respondentId,
      isPreview: !!mandatoryQueryParams.preview,
      enteringDatetime: renderingDateTime,
      submissionDatetime: getNowTimeString(),
      questionId,
      matrix,
      entryPosition: entry.entryPosition,
      renderedEntryPosition: entry.renderedEntryPosition,
      previousEntryPosition: progress?.previousEntryPosition || -1,
      contextPosition: entry.contextPosition,
      renderedContextPosition: entry.renderedContextPosition,
      entryType,
      questionTypeCode,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      responseChoices,
      experimentalDesignVersion,
      utcOffset: getUtcOffset()
    }

    sendResponseChoice(payload, endpoint)
      .then(() => {
        datadogLogs.logger.info('Response choice was sent', {
          responseChoice: {
            questionId,
            matrix,
            responseChoices
          }
        })
      })
      .catch((err) => {
        if (progress) {
          const newProgress = { ...progress }
          newProgress.isQualityTerminated = true
          setProgress(newProgress)
          next()
        } else {
          throw new Error(
            'Could not find respondent progress in useSendResponseChoices'
          )
        }
        datadogLogs.logger.error(
          'Network Failure: Response choice failed to be sent',
          {
            err,
            payload,
            payloadSize: JSON.stringify(payload).length
          }
        )
      })
  }

  return sendResponseOptionChoices
}

export default useSendResponseChoice
