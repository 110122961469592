import { useLocation } from 'react-router-dom'
import { useQuestionnaireParams } from 'hooks/useQuestionnaireParams'

export function useFdChatUrl() {
  const mandatoryParams = useQuestionnaireParams()
  const location = useLocation()

  const { surveyId, respondentId } = mandatoryParams ?? {}
  const searchParams = new URLSearchParams(location.search)
  searchParams.set('hybrid', 'true')
  const isCoworkerProject = searchParams.get('source') === 'coworker'

  // If the project is a coworker project we should redirect to the participant experience domain
  if (isCoworkerProject) {
    searchParams.set('rid', respondentId)
    return `${process.env.REACT_APP_PARTICIPANT_EXPERIENCE_DOMAIN}/interview/${surveyId}?${searchParams}`
  }

  return `${process.env.REACT_APP_FDCHAT_DOMAIN}/${surveyId}/${respondentId}?${searchParams}`
}
