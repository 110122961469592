import React, { useState } from 'react'
import { useFdChatUrl } from 'hooks/useFdChatUrl'
import useStyles from './FdChat.styles'

export const FdChat: React.FC = () => {
  const [fdchatLoaded, setFdchatLoaded] = useState(false)
  const { classes, cx: classNames } = useStyles()
  const fdchatUrl = useFdChatUrl()

  return (
    <>
      <div
        data-testid="spinner"
        className={classNames('cover', classes.spinnerWrapper, {
          fdchatLoading: !fdchatLoaded
        })}
      >
        <div className="spinner">
          <div className="static" />
        </div>
      </div>
      <iframe
        data-testid="iframe"
        title="fdchat"
        onLoad={() => setFdchatLoaded(true)}
        height="100%"
        width="100%"
        className={classNames(classes.iframe, { fdchatLoading: !fdchatLoaded })}
        src={fdchatUrl}
      />
    </>
  )
}
