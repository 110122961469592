import InfoPageLayout from 'layouts/InfoPageLayout/InfoPageLayout'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Text, TextHighlight, TextWeight } from '@focaldata/cin-ui-components'
import checkSrc from 'assets/check.svg'
import { useQuestionnaireParams } from 'hooks/useQuestionnaireParams'
import useSendRespondentStatus from 'hooks/useSendRespondentStatus'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { PanelSupplierCode } from '../../model/common'
import { RespondentProgress } from '../../model/respondentProgress'

const TIMER_START_VALUE = 3

const CompletePage: React.FC = () => {
  const [timer, setTimer] = useState<number>(TIMER_START_VALUE)
  const mandatoryQueryParams = useQuestionnaireParams()
  const {
    respondentProgress: [progress]
  } = useContext<AppState>(AppStateContext)
  const sendRespondentStatus = useSendRespondentStatus()

  const redirectionUrl = (
    respondentPanelSupplierCode: string,
    respondentProgress: RespondentProgress | undefined
  ): string => {
    switch (respondentPanelSupplierCode) {
      case PanelSupplierCode.Cint:
        return `${process.env.REACT_APP_CINT_REDIRECT_URL}/${respondentProgress?.respondentId}`
      case PanelSupplierCode.Prolific:
        return `${process.env.REACT_APP_PROLIFIC_REDIRECT_URL}?cc=${
          respondentProgress?.isCompleted
            ? process.env.REACT_APP_PROLIFIC_COMPLETE_CODE
            : process.env.REACT_APP_PROLIFIC_SCREEN_OUT_CODE
        }`
      default:
        return window.location.href
    }
  }

  const isCompleted = progress?.isCompleted
  const isCustom = mandatoryQueryParams?.panelSupplier === PanelSupplierCode.Fd
  const panelSupplierCode = mandatoryQueryParams?.panelSupplier

  useEffect(() => {
    if (!mandatoryQueryParams?.preview) {
      if (timer === TIMER_START_VALUE) {
        sendRespondentStatus()
      }

      // if the panel supplier is cint or prolific, redirect after 3 seconds
      if (!isCustom) {
        if (timer > 0) {
          setTimeout(() => {
            setTimer(timer - 1)
          }, 1000)
        } else {
          window.location.assign(redirectionUrl(panelSupplierCode, progress))
        }
      }
    }
  })

  const getSubtitle = () => {
    if (isCompleted)
      return isCustom ? 'completePage_closeWindow' : 'completePage_weAppreciate'
    return isCustom
      ? 'completePage_unfortunately_close_page'
      : 'completePage_unfortunately'
  }

  const locIds = new Map<string, string>([
    ['title', isCompleted ? 'completePage_thanks' : 'completePage_thanks2'],
    ['subtitle', getSubtitle()],
    [
      'action',
      mandatoryQueryParams?.preview
        ? 'completePage_preview'
        : 'completePage_redirecting'
    ]
  ])

  return (
    <InfoPageLayout
      visual={<img src={checkSrc} alt="check icon" />}
      title={
        <FormattedMessage
          id={locIds.get('title')}
          defaultMessage="Thanks for completing our survey!"
        />
      }
      subtitle={
        <FormattedMessage
          id={locIds.get('subtitle')}
          defaultMessage={`We appreciate you taking the time to answer the survey. ${
            !isCustom
              ? 'To officially complete this survey, wait until you are redirected.'
              : 'You may close this page.'
          }`}
        />
      }
      action={
        !isCustom ? (
          <Text highlight={TextHighlight.Emphasis} weight={TextWeight.SemiBold}>
            <FormattedMessage
              id={locIds.get('action')}
              defaultMessage="Redirecting to {panel} in {count} seconds"
              values={{ count: timer, panel: panelSupplierCode }}
            />
          </Text>
        ) : undefined
      }
    />
  )
}

export default CompletePage
