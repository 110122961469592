import { LogicClauseProposition } from 'model/questionLogic'

export const groupLogicPropositionsByQuestion = (
  propositionLists: LogicClauseProposition[][]
) => {
  const groupedPropositionLists: Record<string, LogicClauseProposition[]> = {}

  propositionLists.forEach((propositions) => {
    propositions.forEach((proposition) => {
      if (!('questionId' in proposition.proposition)) {
        throw new Error(
          'Question ID is required for grouping logic propositions'
        )
      }
      const { questionId } = proposition.proposition
      if (!groupedPropositionLists[questionId]) {
        groupedPropositionLists[questionId] = []
      }
      groupedPropositionLists[questionId].push(proposition)
    })
  })

  return Object.values(groupedPropositionLists)
}
