export enum EntryType {
  MatrixEntryType = 'MatrixEntryType',
  QuestionEntryType = 'QuestionEntryType',
  TextCardEntryType = 'TextCardEntryType',
  TermsEntryType = 'TermsEntryType'
}

export type SelectedResponses = {
  [rowPosition: number]: [
    {
      responseDateTime: string
      responseOptionId: string
    }
  ]
}

export enum ResponseChoiceValue {
  Selected = 'Selected',
  NotSelected = 'NotSelected'
}

export enum PanelSupplierCode {
  Cint = 'cint',
  Prolific = 'prolific',
  Fd = 'fd'
}
