import { datadogLogs } from '@datadog/browser-logs'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { RespondentInformation } from 'model/respondentInformation'
import { useContext } from 'react'
import { RESPONDENT_INFO_API_PATH } from 'utils/constants'
import { getNowTimeString, getUtcOffset } from 'utils/datetime'
import { apiUrlEndpoint } from 'utils/helper'
import { useCintQueryParams } from './useCintQueryParams'
import { useQuestionnaireParams } from './useQuestionnaireParams'
import { usePostCallbackWithRetry } from './usePostCallback'
import { useMetadataQueryParams } from './useMetadataQueryParams'

const endpoint = `${apiUrlEndpoint}${RESPONDENT_INFO_API_PATH}`

const useSendRespondentInfo = () => {
  const mandatoryQueryParams = useQuestionnaireParams()
  const cintQueryParams = useCintQueryParams()
  const metadataQueryParams = useMetadataQueryParams()

  const {
    renderedQuestionnaire: [questionnaireData]
  } = useContext<AppState>(AppStateContext)
  const sendRespondentInfoInternal =
    usePostCallbackWithRetry<RespondentInformation>()

  const sendRespondentInfo: (
    recaptchaUserToken?: string
  ) => Promise<any> = async (recaptchaUserToken) => {
    if (!mandatoryQueryParams) {
      // NOTE: should never get here
      datadogLogs.logger.error(
        'Respondent info failed to be sent. Mandatory params are missing. Ensure `sid`, `rid` and `ps` are specified. If they are, respondent progress is missing'
      )
      Promise.resolve()
    }
    datadogLogs.logger.info('Sending respondent info')

    const payload: RespondentInformation = {
      ...cintQueryParams,
      respondentId: mandatoryQueryParams.respondentId,
      surveyId: mandatoryQueryParams.surveyId,
      panelSupplierCode: mandatoryQueryParams.panelSupplier,
      isPreview: mandatoryQueryParams.preview,
      metaData: { queryParams: metadataQueryParams },
      submissionDatetime: getNowTimeString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utcOffset: getUtcOffset(),
      locale: questionnaireData?.questionnaire.locale,
      recaptchaUserToken
    }

    try {
      const timeStart = performance.now()
      await sendRespondentInfoInternal(payload, endpoint)
      const timeEnd = performance.now()
      datadogLogs.logger.info('Respondent info was sent', {
        duration: timeEnd - timeStart
      })
    } catch (err) {
      datadogLogs.logger.error(
        'Network Failure: Respondent info failed to be sent',
        {
          err,
          payload,
          payloadSize: JSON.stringify(payload).length
        }
      )
    }
  }

  return sendRespondentInfo
}

export default useSendRespondentInfo
