import { EntryType } from 'model/common'
import {
  LogicClauseProposition,
  QuestionResponseOption,
  MatrixResponseOption,
  LogicPropositionType,
  LogicClauseAsNumber
} from 'model/questionLogic'
import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { isQuestionHiddenByDisplayLogic } from './hiddenByDisplayLogic'
import getPersistedRespondentChoices from './getPersistedRespondentChoices'
import { groupLogicPropositionsByQuestion } from './groupLogicPropositions.utils'

export const checkIsPropositionInLocalstorageOrNot = (
  respondentId: string,
  surveyId: string,
  persistenRespondentChoices: PersistentRespondentChoice[],
  proposition: LogicClauseProposition
) => {
  const isPropositionNegated = proposition.negated

  if (
    proposition.propositionType ===
    LogicPropositionType.QuestionResponseOptionType
  ) {
    const questionProposition =
      proposition.proposition as QuestionResponseOption

    // Checking if the question to which this current entity depends on has been hidden by display logic
    // if it has, skip this one too
    // if not, do the rest of the checks
    const hasPreviousBeenHiddenByDisplayLogic = isQuestionHiddenByDisplayLogic(
      respondentId,
      surveyId,
      questionProposition.questionId
    )

    // in case there was a question which is on another fork branch there still may be questions with display logic refering to it. in this case such questions should not be displayed.
    // Example:
    // Fork A with Q1, Q3
    // Fork B with Q1, Q2, Q3
    // if Q3 has display logic refering to Q2 response then Fork A should show only Q1 since visibility of Q3 depends on Q2 which is absent in Fork A
    // Similar approach is relevant for skipped questions, i.e. do not show dependant question if it refers to the skipped one
    const isPresentInPreviousResponses = persistenRespondentChoices.some(
      ({ questionId }) => questionProposition.questionId === questionId
    )

    if (hasPreviousBeenHiddenByDisplayLogic || !isPresentInPreviousResponses) {
      return false
    }

    // Only check the `questionProposition` that matched from the `persistenRespondentChoices`
    const isQuestionPropositionInLocalstorage = persistenRespondentChoices.some(
      (persistenRespondentChoice) =>
        persistenRespondentChoice.questionId ===
          questionProposition?.questionId &&
        persistenRespondentChoice.responseChoiceIds.includes(
          questionProposition.responseOptionId
        )
    )

    // If proposition is negated, it should not exist in the local storage
    // Otherwise, it should exist in the local storage
    return isPropositionNegated
      ? !isQuestionPropositionInLocalstorage
      : isQuestionPropositionInLocalstorage
  }

  if (
    proposition.propositionType ===
    LogicPropositionType.MatrixResponseOptionType
  ) {
    const matrixProposition = proposition.proposition as MatrixResponseOption

    // Checking if the question to which this current entity depends on has been hidden by display logic
    // if it has, skip this one too
    // if not, do the rest of the checks
    const hasPreviousBeenHiddenByDisplayLogic = isQuestionHiddenByDisplayLogic(
      respondentId,
      surveyId,
      matrixProposition.matrixTitleId
    )

    if (hasPreviousBeenHiddenByDisplayLogic) {
      return false
    }

    const isMatrixPropositionInLocalstorage = persistenRespondentChoices.some(
      (persistenRespondentChoice) =>
        persistenRespondentChoice.questionId ===
          matrixProposition?.questionId &&
        persistenRespondentChoice.responseChoiceIds.includes(
          matrixProposition.responseOptionId
        ) &&
        persistenRespondentChoice.matrixTitleId ===
          matrixProposition.matrixTitleId
    )

    // If proposition is negated, it should not exist in the local storage
    // Otherwise, it should exist in the local storage
    return isPropositionNegated
      ? !isMatrixPropositionInLocalstorage
      : isMatrixPropositionInLocalstorage
  }
  return false
}

const hasValidProposition = (
  respondentId: string,
  surveyId: string,
  propositions: LogicClauseProposition[],
  persistentRespondentChoices: PersistentRespondentChoice[]
): boolean => {
  // Example 1: If the answer to Q1 is R1 OR R2
  // Example 2: If the answer to Q1 is NOT R1 OR R2
  // If negated clause block, every proposition should be valid
  //   - For Example 2, R1 or R2 should not be answered in Q1 in the local storage
  // Otherwise, one valid proposition is enough

  const negatedPropositions = propositions.filter(
    (proposition) => proposition.negated
  )

  const nonNegatedPropositions = propositions.filter(
    (proposition) => !proposition.negated
  )

  // Note: I have to add negatedPropositions.length > 0 so it will not give a false-positive result
  // Using .every on an empty array always returns `true`
  const everyPropositionIsValid =
    negatedPropositions.length > 0 &&
    negatedPropositions.every((proposition) =>
      checkIsPropositionInLocalstorageOrNot(
        respondentId,
        surveyId,
        persistentRespondentChoices,
        proposition
      )
    )

  const somePropositionIsValid =
    nonNegatedPropositions.length > 0 &&
    nonNegatedPropositions.some((proposition) =>
      checkIsPropositionInLocalstorageOrNot(
        respondentId,
        surveyId,
        persistentRespondentChoices,
        proposition
      )
    )

  // Since this is a "OR", one valid proposition is enough
  return everyPropositionIsValid || somePropositionIsValid
}

const getQuestionLogicClause = (
  propositionLists: LogicClauseProposition[][]
): number => {
  // E.g.
  //   If answer to Q1 is NOT R1
  //   AND
  //   If answer to Q2 is R1 OR R2 <-- the operator is saved in this item
  // E.g.
  //   If answer to Q1 is NOT R1 <-- the operator is saved either here
  //   OR
  //   If answer to Q2 is R1 OR R2 <-- or here

  // Getting the operator from the second proposition (clause block) is enough
  // propositionLists must be at least 2
  const secondProposition = propositionLists.length > 1 && propositionLists[1]

  return (
    (secondProposition && secondProposition[0]?.clause) ||
    LogicClauseAsNumber.OR
  )
}

const showEntityBasedOnLogic = (
  respondentId: string,
  surveyId: string,
  propositionLists: LogicClauseProposition[][],
  persistentRespondentChoices: PersistentRespondentChoice[]
) => {
  // Determine whether the clause is AND or OR by getting it from any of the propositions (clause blocks)
  const isAndOperator =
    getQuestionLogicClause(propositionLists) === LogicClauseAsNumber.AND

  // If it is an AND operator, check every propositions condition
  // Else if it is an OR operator, check some propositions condition

  return isAndOperator
    ? propositionLists.every((propositions) =>
        hasValidProposition(
          respondentId,
          surveyId,
          propositions,
          persistentRespondentChoices
        )
      )
    : groupLogicPropositionsByQuestion(propositionLists).some((propositions) =>
        hasValidProposition(
          respondentId,
          surveyId,
          propositions,
          persistentRespondentChoices
        )
      )
}

const displayEntityBasedOnLogic = (
  respondentId: string,
  surveyId: string,
  entityLogic: LogicClauseProposition[][] | undefined,
  entryType: EntryType
) => {
  const persistenRespondentChoices = getPersistedRespondentChoices(
    respondentId,
    surveyId
  )

  if (
    entityLogic &&
    entityLogic.length > 0 &&
    persistenRespondentChoices &&
    persistenRespondentChoices.length > 0
  ) {
    if (
      entryType === EntryType.QuestionEntryType ||
      entryType === EntryType.MatrixEntryType ||
      entryType === EntryType.TextCardEntryType
    ) {
      const shouldDisplayEntity = showEntityBasedOnLogic(
        respondentId,
        surveyId,
        entityLogic,
        persistenRespondentChoices
      )
      return shouldDisplayEntity
    }

    return true // For now, always display if it is not a QuestionEntryType or a MatrixEntryType or TextCardEntryType
  }

  return true // Always display if there is no logic
}

export default displayEntityBasedOnLogic
