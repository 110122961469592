import useQueryParams from './useQueryParams'
import { useQuestionnaireParams } from './useQuestionnaireParams'
import { useCintQueryParams } from './useCintQueryParams'

export interface MetadataQueryParams {
  [key: string]: string | null
}

export const useMetadataQueryParams = () => {
  const queryParams = useQueryParams()
  const mandatoryQueryParams = useQuestionnaireParams()
  const cintQueryParams = useCintQueryParams()
  const combinedQueryParamValues = [
    ...Object.values(mandatoryQueryParams),
    ...Object.values(cintQueryParams)
  ]

  const metadataQueryParams: MetadataQueryParams = {}

  queryParams.forEach((value: string | null, key: string | number) => {
    if (!Object.values(combinedQueryParamValues).includes(<string>value)) {
      metadataQueryParams[key] = value
    }
  })

  return metadataQueryParams
}
